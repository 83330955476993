import { arrowDownIcon } from './arrow-down';
import { arrowLeftIcon } from './arrow-left';
import { arrowRightIcon } from './arrow-right';
import { arrowUpIcon } from './arrow-up';
import { controlBarIcon } from './control-bar';
import { dragHandleIcon } from './drag-handle';
import { durationIcon } from './duration';
import { fullscreenOffIcon } from './fullscreen-off';
import { fullscreenOnIcon } from './fullscreen-on';
import { homeIcon } from './home';
import { nextSlideIcon } from './next-slide';
import { pauseIcon } from './pause';
import { playIcon } from './play';
import { playlistIcon } from './playlist';
import { previousSlideIcon } from './previous-slide';
import { replayIcon } from './replay';
import { skipBackIcon } from './skip-back';
import { skipForwardIcon } from './skip-forward';
import { timeSpentIcon } from './time-spent';
import { tocIcon } from './toc';
import { versionsSelectedIcon } from './versions-selected';
import { versionsUnselectedIcon } from './versions-unselected';
import { versionsIcon } from './versions';
import { volumeOffIcon } from './volume-off';
import { volumeOnIcon } from './volume-on';
export const playerIcons = [arrowDownIcon, arrowLeftIcon, arrowRightIcon, arrowUpIcon, controlBarIcon, dragHandleIcon, durationIcon, fullscreenOffIcon, fullscreenOnIcon, homeIcon, nextSlideIcon, pauseIcon, playIcon, playlistIcon, previousSlideIcon, replayIcon, skipBackIcon, skipForwardIcon, timeSpentIcon, tocIcon, versionsSelectedIcon, versionsUnselectedIcon, versionsIcon, volumeOffIcon, volumeOnIcon];
