import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { FontManager } from '@modulae/types';

export interface StageConfig{
  scaleMax100?: boolean;
  showTextDebug?: boolean;
  enableStudio?: boolean;
}

export const RENDERERS_FONT_MANAGER = new InjectionToken<Observable<FontManager>>('RENDERERS_FONT_MANAGER');
export const STAGE_CONFIG = new InjectionToken<StageConfig>('STAGE_CONFIG');
export const RENDERERS_MEDIA_MUTED = new InjectionToken<Observable<boolean>>('RENDERERS_MEDIA_MUTED');
export const RENDERERS_USER_FULL_NAME = new InjectionToken<Observable<string|undefined>>('RENDERERS_USER_FULL_NAME');

export const SLIDE_RENDERERS_MAP = new InjectionToken<Map<string,  () => Promise<any>>>('SLIDE_RENDERERS_MAP');
