import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { provideRouter, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { DialogModule } from '@angular/cdk/dialog';
import { PwaManifestModule } from '@madeinlune/ngx-pwa-manifest';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { extModules } from './build-specifics';
import { PwaService } from '@nx-modulae/pwa';
import { playerIcons, programIcons, storyboardIcons } from '@modulae/icons';
import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { APP_CONFIG, getAppConfigProvider } from '@madeinlune/ngx-app-config';
import { getBrowserLang, provideTransloco, TranslocoService } from '@ngneat/transloco';
import { LOCATION } from '@ng-web-apis/common';
import { PreferencesService } from '@nx-modulae/preferences/feature';
import { routerReducer } from '@ngrx/router-store';
import { Observable, of, tap } from 'rxjs';
import { prefsSchema } from './prefs-schema';
import { SLIDE_RENDERERS_MAP } from '@modulae/player-renderers/tokens';
import PiwikPro from '@piwikpro/pwa-piwik-pro';
import { NgxPiwikProModule, NgxPiwikProRouterModule } from '@piwikpro/ngx-piwik-pro';
import { Location } from '@angular/common';
import { provideProgramConfig } from '@nx-modulae/program/config';
import { GetLangParams, provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { TranslocoHttpLoader } from './transloco-http-loader';
import { provideThreeJSRenderers } from '@nx-modulae/slide/templates/lab/providers';
import { CODE } from '@modulae-ui/entry-code';
import { CO_PILOT_ENABLED } from '@modulae/ngx-program-player/tokens';

const urlObject = new URL(document.location.href);
const backendUrl = urlObject.searchParams.get('b')??environment.config.backendUrl;
function getLangFn(langs: GetLangParams) {
  const urlObject = new URL(document.location.href);
  if (urlObject.search) {
    const search = urlObject.search;
    const startUpSearchParams = new URLSearchParams(search);
    const lang = startUpSearchParams.get('lang');
    if (lang) {
      return lang;
    }
  }
  if (langs.cachedLang) {
    return langs.cachedLang;
  }
  return langs.browserLang === 'fr' || langs.browserLang === 'ar' ? langs.browserLang : 'en';
}

function initializeTransloco(
  translocoService: TranslocoService,
  location: Location
): () => Observable<void> {
  return () =>
    of().pipe(
      tap(() => {
        const browserLang: string | undefined = getBrowserLang();
        if (browserLang === 'fr') {
          translocoService.setActiveLang('fr');
        } else {
          translocoService.setActiveLang('en');

        }
      })
    );
}

export function preferencesServiceFactory(
  preferencesService: PreferencesService
): () => Observable<any> {
  return () =>
    preferencesService.loadPreferencesFromLocalStorage(
      'modulae-program-prefs',
      prefsSchema
    );
}

const threeJSRenderersMap = new Map();
threeJSRenderersMap.set('vcHpWelcomeScreen', () =>
  import('@nx-modulae/slide/templates/lab').then(
    (m) => m.VanCleefWelcomeSceneComponent
  )
);

threeJSRenderersMap.set('perfumeUniverse', () =>
  import('@nx-modulae/slide/templates/lab').then(
    (m) => m.PerfumeUniverseComponent
  )
);
export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: CO_PILOT_ENABLED,
      useValue: true
    },
    {
      provide: CODE,
      useValue: 'mdli2l'
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        PiwikPro.enableInternetConnectionTracking();
        PiwikPro.enableInstallTracking();
      },
      multi: true
    },
    provideProgramConfig(APP_CONFIG),
    provideTransloco(
      {
        config: {
          availableLangs: ['en', 'fr', 'ar'],
          // Remove this option if your application
          // doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: !isDevMode()
        },
        loader: TranslocoHttpLoader
      }
    ),
    provideTranslocoPersistLang({
      getLangFn,
      storage: {
        useValue: localStorage
      }
    }),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
      HttpClientModule,
      DialogModule,
      PwaManifestModule.forRoot(),
      StoreModule.forRoot(
        {
          router: routerReducer
        },
        {
          metaReducers: [],
          runtimeChecks: {
            strictActionImmutability: true,
            strictStateImmutability: true
          }
        }
      ),
      EffectsModule.forRoot([]),
      BrowserAnimationsModule,
      ...extModules,
      NgxPiwikProModule.forRoot(
        '98e50b28-4c90-4bdb-9007-75ffbc4b038e',
        'https://icilalune.containers.piwik.pro'
      ),
      NgxPiwikProRouterModule
    ),
    PwaService,
    provideRouter([
      {
        path: '',
        loadChildren: () =>
          import('@modulae/ngx-program-shell-feature/routes').then(
            (m) => m.SHELL_ROUTES
          )
      }
    ]),
    provideSvgIconsConfig({
      sizes: {
        sm: '12px',
        md: '15px',
        lg: '30px'
      },
      defaultSize: 'md'
    }),
    provideSvgIcons(playerIcons),
    provideSvgIcons(programIcons),
    provideSvgIcons(storyboardIcons),
    getAppConfigProvider({backendUrl}),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService, LOCATION],
      useFactory: initializeTransloco
    },
    PreferencesService,
    {
      provide: APP_INITIALIZER,
      useFactory: preferencesServiceFactory,
      multi: true,
      deps: [PreferencesService]
    },
    {
      provide: SLIDE_RENDERERS_MAP,
      useFactory: () => {
        const slidesMap = new Map();
        slidesMap.set('lab', () =>
          import('@nx-modulae/slide/templates/lab').then(
            (m) => m.SlideTemplatesLabComponent
          )
        );
        slidesMap.set('threeJsDefault', () =>
          import('@nx-modulae/slide/templates/lab').then(
            (m) => m.SlideThreejsSceneComponent
          )
        );
        slidesMap.set('freeOpenBar', () =>
          import('@nx-modulae/slide/templates/lab').then(
            (m) => m.FreeOpenBarComponent
          )
        );
        return slidesMap;
      }
    },
    provideThreeJSRenderers({ renderersMap: threeJSRenderersMap })
  ]
};
