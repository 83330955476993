import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, EventEmitter, HostListener,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { ComponentStore } from '@ngrx/component-store';
import { startWith, tap } from 'rxjs';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { CODE } from './index';

@Component({
  selector: 'mdl-ui-entry-code',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SvgIconComponent],
  template: `
    <header>
      <img [src]='appIcon' />
      <h1 [innerHtml]='moduleTitle'></h1>
    </header>
    <main>
      <form [formGroup]='codeForm'>
        <input #digit1 type='text' formControlName='digit1' maxlength='1' (input)='focusInput(digit2, $event)'>
        <input #digit2 type='text' formControlName='digit2' maxlength='1' (input)='focusInput(digit3, $event)'
               (keydown)='onKeyDown($event, digit1)'>
        <input #digit3 type='text' formControlName='digit3' maxlength='1' (input)='focusInput(digit4, $event)'
               (keydown)='onKeyDown($event, digit2)'>
        <input #digit4 type='text' formControlName='digit4' maxlength='1' (input)='focusInput(digit5, $event)'
               (keydown)='onKeyDown($event, digit3)'>
        <input #digit5 type='text' formControlName='digit5' maxlength='1' (input)='focusInput(digit6, $event)'
               (keydown)='onKeyDown($event, digit4)'>
        <input #digit6 type='text' formControlName='digit6' maxlength='1' (keydown)='onKeyDown($event, digit5)'
               (input)='unfocus(digit6)'>
        <button (click)='clear()' class='clear'>
          <svg-icon [key]='"close"'></svg-icon>
        </button>
      </form>

      <p>Merci de saisir votre code</p>

      <button class='btn accent' type='submit' (click)='submitPassword()'>
        Submit
      </button>

    </main>
  `,
  styleUrl: './entry-code.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryCodeComponent extends ComponentStore<any> implements AfterViewInit {

  private localStorage = inject(LOCAL_STORAGE);
  private el: HTMLElement = inject(ElementRef).nativeElement;
  private fb = inject(FormBuilder);

  private code = inject(CODE);

  protected codeForm = this.fb.group({
    digit1: ['', Validators.required],
    digit2: ['', Validators.required],
    digit3: ['', Validators.required],
    digit4: ['', Validators.required],
    digit5: ['', Validators.required],
    digit6: ['', Validators.required]
  });

  @Input({ required: true })
  appIcon: string | undefined;

  @Input({ required: true })
  moduleTitle: string | undefined;

  @Output()
  codeMatches: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.submitPassword();
  }

  @ViewChild('digit1') digit1: ElementRef<HTMLInputElement> | undefined;

  onFormChanges = this.effect(() => {
    return this.codeForm.valueChanges.pipe(
      startWith(null),
      tap((value: any) => {
        this.el.classList.remove('empty-form');
        if (value) {
          const isEmpty = Object.keys(value).filter(key => {
            return value[key] !== null;
          }).length === 0;
          if (isEmpty) {
            this.el.classList.add('empty-form');
          }
        }else{
          this.el.classList.add('empty-form');
        }
      })
    );
  });

  ngAfterViewInit(): void {
    this.focusFirstInput();
  }

  focusFirstInput() {
    if (this.digit1) {
      this.digit1.nativeElement.focus();
    }
  }

  submitPassword() {
    const pass = this.code;
    const formPass = Object.values(this.codeForm.value).join('');
    if (formPass.toLowerCase() === pass.toLowerCase()) {
      this.localStorage.setItem('pass', pass);
      this.codeMatches.emit(true);
    }
  }

  focusInput(inputField: HTMLInputElement, event: Event | undefined = undefined) {
    inputField.focus();
    inputField.selectionStart = inputField.selectionEnd = 0;
  }

  onKeyDown(event: KeyboardEvent, inputField: HTMLInputElement) {
    if (event.key === 'Backspace') {
      const inputTarget = event.target as HTMLInputElement;
      inputTarget.value = '';
      this.focusInput(inputField);
      event.preventDefault();
    }
  }

  unfocus(inputField: HTMLInputElement) {
    inputField.selectionStart = inputField.selectionEnd = 0;
    inputField.blur();
  }

  clear() {
    this.codeForm.reset();
    this.focusFirstInput();
  }
}
