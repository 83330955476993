import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { FormSchema } from '@nx-modulae/preferences/types';
import { debounceTime, of, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';

interface PreferencesState {
  schema?: FormSchema;
  preferences?: any | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class PreferencesService extends ComponentStore<PreferencesState> {

  private readonly document = inject(DOCUMENT);
  private fb = inject(FormBuilder);

  private readonly preferences$ = this.select(s => s.preferences);
  private readonly schema$ = this.select(s => s.schema);

  key = 'prefs;';

  vm$ = this.select({
    preferences: this.preferences$,
    schema: this.schema$
  });

  savePreferences = this.effect(() => {
    return this.preferences$.pipe(
      debounceTime(200),
      tap(preferences => {
        if (this.document.defaultView && preferences) {
          this.document.defaultView.localStorage.setItem(this.key, JSON.stringify(preferences));
        }
      })
    );
  });

  constructor() {
    super({});
  }

  loadPreferencesFromLocalStorage(key: string, schema: FormSchema) {
    this.key = key;
    return of(null).pipe(
      tap(() => {
        this.patchState({ schema });
        console.log('loadPreferencesFromLocalStorage()');
        if (this.document.defaultView) {
          const rawPreferences = this.document.defaultView.localStorage.getItem(this.key);
          this.patchState({ preferences: rawPreferences ? JSON.parse(rawPreferences) : undefined });
        }
      })
    );
  }

  buildFormGroup(): FormGroup {
    const formSchema: FormSchema | undefined = this.get(s => s.schema);
    if (formSchema) {
      const controlsConfig: Record<string, any> = {};
      for (const field of formSchema.fields) {
        const validators = [];
        if (field.validators) {
          validators.push(...(Array.isArray(field.validators) ? field.validators : [field.validators]));
        }
        if (field.type === 'text' || field.type === 'email' || field.type === 'password') {
          controlsConfig[field.name] = [field.value || '', validators];
        } else if (field.type === 'number') {
          controlsConfig[field.name] = [field.value || null, validators];
        } else if (field.type === 'textarea') {
          controlsConfig[field.name] = [field.value || '', validators];
        } else if (field.type === 'checkbox') {
          controlsConfig[field.name] = [field.value || false, validators];
        } else if (field.type === 'radio') {
          controlsConfig[field.name] = [field.value || null, validators];
        } else if (field.type === 'select') {
          controlsConfig[field.name] = [field.value || null, validators];
        }
      }
      const preferences = this.get(s => s.preferences);
      const form = this.fb.group(controlsConfig);
      if (preferences) {
        for (const key in preferences) {
          if (!form.controls[key]) {
            delete preferences[key];
          }
        }
        form.patchValue(preferences);
      }
      return form;
    }
    return this.fb.group({});
  }

  setPreferences(preferences: any): void {
    this.patchState({ preferences });
  }

  setPreference(key: string, value: any) {
    this.patchState(state => {
      return {
        preferences: {
          ...state.preferences,
          [key]: value
        }
      };
    });
  }

  getPreference(key: string) {
    console.log('this.preferences', this.preferences);
    return this.preferences?.[key];
  }

  get preferences() {
    return this.get(s => s.preferences);
  }

}
