import { InjectionToken } from '@angular/core';
import { SwitcherConfig } from '@modulae/utils/config-switcher';
import { Observable } from 'rxjs';
import { Program } from '@modulae/types/program';
import { UserInfos } from '@modulae/types';

export type ProgramConfig = {
  projectId: string;
  static: boolean;
  s3Region: string;
  s3BucketName: string;
  version: number;
}

export const PROGRAM_CONFIG = new InjectionToken<ProgramConfig>('PROGRAM_CONFIG');
export const PROGRAM_ID = new InjectionToken<string>('PROGRAM_ID');

export const ROUTE_PROGRAM_ID = new InjectionToken<string>('ROUTE_PROGRAM_ID');
export const LOCAL_TEST_CONFIGS = new InjectionToken<SwitcherConfig[]>('LOCAL_TEST_CONFIGS');
export const SELECTED_LOCAL_TEST_CONFIGS = new InjectionToken<SwitcherConfig | undefined>('SELECTED_LOCAL_TEST_CONFIGS');

export const DB_METADATA = new InjectionToken<Observable<{ key: string, value: string }[]>>('DB_METADATA');

export const DB_CONTENT_VERSION = new InjectionToken<Observable<number>>('DB_CONTENT_VERSION');
export const DB_CONTENT_INSTALLING_VERSION = new InjectionToken<Observable<number>>('DB_CONTENT_INSTALLING_VERSION');
export const DB_SUCCESSFULLY_INSTALLED = new InjectionToken<Observable<number>>('DB_SUCCESSFULLY_INSTALLED');

export const APP_READY = new InjectionToken<Observable<boolean>>('APP_READY');
export const LOADING_LOGO = new InjectionToken<string>('LOADING_LOGO');
export const PROGRAM = new InjectionToken<Observable<Program>>('PROGRAM');

export const ROUTE_LANGUAGE = new InjectionToken<Observable<string>>('ROUTE_LANGUAGE');
export const ROUTE_SECTION = new InjectionToken<Observable<'home'|'storyboard'|'rehearsal'|'play'>>('ROUTE_SECTION');

export const ROUTE_IS_CUSTOMIZATION = new InjectionToken<Observable<boolean>>('ROUTE_IS_CUSTOMIZATION');

export const ROUTE_SLIDE_TITLE = new InjectionToken<Observable<string>>('ROUTE_SLIDE_TITLE');

export const PROGRAM_VERSION = new InjectionToken<Observable<string>>('PROGRAM_VERSION');
export const S3_BASE_URL = new InjectionToken<Observable<string>>('S3_BASE_URL');
export const USER_INFOS = new InjectionToken<Observable<UserInfos|undefined>>('USER_INFOS');
export const USER_FIRST_NAME = new InjectionToken<Observable<string|undefined>>('USER_FIRST_NAME');

export const USER_FULL_NAME = new InjectionToken<Observable<string|undefined>>('USER_FULL_NAME');
export const USER_MAIL = new InjectionToken<Observable<string|undefined>>('USER_MAIL');

export const SHELL_INTERNAL_USER_INFOS = new InjectionToken<Observable<UserInfos|undefined>>('SHELL_INTERNAL_USER_INFOS');
