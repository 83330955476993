import { Provider } from '@angular/core';
import { BRAND_LOGO, THREEJS_RENDERERS_MAP } from '@nx-modulae/slide/templates/lab/tokens';
import { Observable } from 'rxjs';

export function provideThreeJSRenderers(params: {
  renderersMap: Map<string, () => Promise<any>>
  brandLogo?: Observable<string>
}): Provider[] {
  const { renderersMap, brandLogo } = params;
  return [
    {
      provide: THREEJS_RENDERERS_MAP,
      useValue: renderersMap
    },
    {
      provide: BRAND_LOGO,
      useValue: brandLogo
    }
  ];
}
