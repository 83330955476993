import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { AppConfig } from '@madeinlune/ngx-app-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private config: any;

  private http = inject(HttpClient);
  private document = inject(DOCUMENT);
  private location = inject(Location);

  load(configUrl: string, appConfig: AppConfig | undefined): Observable<any> {

    return this.http
      .get(configUrl).pipe(
        tap((config: any) => {
          const localConfigs = JSON.parse(
            localStorage.getItem('configs') ?? '[]'
          );
          const enabledLocalConfig = localConfigs.find(
            (c: any) => !!c.defaultEnabled
          );
          const localConfigProgramId = enabledLocalConfig?.id;
          const dynamicProgramId = appConfig?.['programId'];
          const url = new URL(this.document.location.href);
          const urlModuleId = url.searchParams.get('moduleId');
          let defaultLocalLink: string | null = null;
          if (localStorage) {
            defaultLocalLink = localStorage.getItem('defaultLocalLink');
          }
          const finalProgramId =
            urlModuleId ?? defaultLocalLink ?? localConfigProgramId ?? config?.projectId ?? dynamicProgramId;
          this.config = {
            ...config,
            projectId: finalProgramId
          }
          if (urlModuleId) {
            //add moduleId into LocalStorage to be able to display a list of saved moduleIds
            // Remove the moduleId parameter from the URL
            url.searchParams.delete('moduleId');

            if (localStorage) {
              const rawLocalModuleLinks = localStorage.getItem('localLinks');
              const localModuleLinks: {
                id: string,
                name?: string | undefined
              }[] = rawLocalModuleLinks ? JSON.parse(rawLocalModuleLinks) : [];
              const moduleIdExists = !!localModuleLinks.find(lml => lml.id === urlModuleId);
              if (!moduleIdExists) {
                localModuleLinks.push({ id: urlModuleId });
                localStorage.setItem('localLinks', JSON.stringify(localModuleLinks));
              }
              localStorage.setItem('defaultLocalLink', urlModuleId);
            }

            // Update the browser's displayed URL without a page reload
            const newPath = url.pathname + url.search;
            this.location.replaceState(newPath);
          }
        })
      );
  }

  // Helper method to access the loaded configuration
  getConfig(): any {
    return this.config;
  }

}
