import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { NgIf } from '@angular/common';
import { EntryCodeComponent } from '@modulae-ui/entry-code';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';

@Component({
  selector: 'app-root',
  standalone: true,
  template: `
    <ng-container *ngrxLet='restricted$; let restricted'>
      <div *ngIf='restricted' class='restricted-group'>
        <ng-container *ngrxLet='pwaManifestService.manifest$; let manifest'>
          <mdl-ui-entry-code [appIcon]='appIconUrl' [moduleTitle]='manifest.name' (codeMatches)='onCodeMatches($event)'></mdl-ui-entry-code>
        </ng-container>
      </div>
      <router-outlet *ngIf='!restricted'></router-outlet>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, ReactiveFormsModule, LetDirective, NgIf, EntryCodeComponent],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  localStorage = inject(LOCAL_STORAGE);
  pwaManifestService = inject(PwaManifestService)
  appIconUrl = './assets/icons/icon-256x256.png';
  restricted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    !this.localStorage.getItem('pass')
  );

  onCodeMatches(matches: boolean) {
    if(matches){
      this.restricted$.next(false);
    }
  }
}
