import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Sentry from "@sentry/browser";
import { AppComponent } from './app/app.component';

const piwikProConfig = {
  containerId: '',
  containerURL: ''
};

Sentry.init({
  dsn: "https://d5493b0aa15d429eb436bc2db1f32907@o231483.ingest.sentry.io/4505229036748800",
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  transportOptions: {
    dbName: "sentry-offline",
    storeName: "queue",
    maxQueueSize: 30,
    flushAtStartup: false,
    shouldStore: (envelope, error, retryDelay) => true,
  }
});

export function loadConfig(http: HttpClient): Observable<any> {
  return http.get('./config.json');
}

/*fetch('/config.json')
  .then((response) => response.json())
  .then((config: any) => {
    piwikProConfig.containerId = config.containerId;
    piwikProConfig.containerURL = config.containerURL;
    //overrideConsole();
    bootstrapApplication(AppComponent, appConfig);
  });*/
bootstrapApplication(AppComponent, appConfig);
