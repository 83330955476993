import { arrowCloseIcon } from './arrow-close';
import { arrowOpenIcon } from './arrow-open';
import { associatedFilesIcon } from './associated-files';
import { closeIcon } from './close';
import { coPilotIcon } from './co-pilot';
import { editCustomizationIcon } from './edit-customization';
import { favoriteIcon } from './favorite';
import { launchAppIcon } from './launch-app';
import { logOutIcon } from './log-out';
import { menuIcon } from './menu';
import { rehearsalIcon } from './rehearsal';
import { reloadIcon } from './reload';
import { removeCustomizationIcon } from './remove-customization';
import { rocketIcon } from './rocket';
import { slideIcon } from './slide';
import { storyboardIcon } from './storyboard';
import { studioIcon } from './studio';
export const programIcons = [arrowCloseIcon, arrowOpenIcon, associatedFilesIcon, closeIcon, coPilotIcon, editCustomizationIcon, favoriteIcon, launchAppIcon, logOutIcon, menuIcon, rehearsalIcon, reloadIcon, removeCustomizationIcon, rocketIcon, slideIcon, storyboardIcon, studioIcon];
