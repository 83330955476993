import { APP_INITIALIZER, Provider } from '@angular/core';
import { ConfigLoaderService } from './config-loader.service';
import { AppConfig } from '@madeinlune/ngx-app-config';
import { PROGRAM_CONFIG } from '@modulae/ngx-program-shell-feature/tokens';

export function provideProgramConfig(appConfig: AppConfig | undefined): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (loader: ConfigLoaderService, appConfig: AppConfig | undefined) => {
        return () => loader.load('./config.json', appConfig);
      },
      deps: [ConfigLoaderService],
      multi: true
    },
    {
      provide: PROGRAM_CONFIG,
      useFactory: (configLoaderService: ConfigLoaderService) => {
        return configLoaderService.getConfig();
      },
      deps: [ConfigLoaderService]
    }
  ];
}
