import { FormSchema } from 'libs/preferences/types/src';

export const prefsSchema: FormSchema = {
  fields: [
    /*    {
      name: 'grid',
      label: 'Display a 10px grid on the player background in the Rehearsal mode.',
      type: 'checkbox',
      validators: [],
      value: false
    },*/
    {
      name: 'textDebug',
      label: 'Display text areas on animations in the player.',
      type: 'checkbox',
      validators: [],
      value: false,
    },
    {
      name: 'linksOnGuidelines',
      label: 'Display Link buttons on Guidelines',
      type: 'checkbox',
      validators: [],
      value: false,
    },
    {
      name: 'scaleMax',
      label:
        'Display the slides at 100%. Available in Player mode and in Rehearsal mode.',
      type: 'checkbox',
      validators: [],
      value: false,
    },
    {
      name: 'enableStudio',
      label: 'Enable Studio',
      type: 'checkbox',
      validators: [],
      value: false,
    },
  ],
};
